import React, { useState, useEffect, useRef } from 'react'
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { Trans } from 'react-i18next'
import * as AWS from 'aws-sdk'
import "./Pages.css";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import TextToHtml from './TextToHtml.tsx';
import { useTranslation } from 'react-i18next';

AWS.config.update({
  region: 'eu-central-1',
  endpoint: 'dynamodb.eu-central-1.amazonaws.com',
  accessKeyId: 'AKIAQ3EGPQRHF4UWAN22',
    secretAccessKey: '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'
});

const docClient = new AWS.DynamoDB.DocumentClient();

const ProjectsHistory = () => {

  // Column Definitions: Defines the columns to be displayed.
 const [colDefs] = useState([
  { field: "ProjectId" },
  { field: "ProjectDate", filter: true },
  { field: "ProjectType",filter: true },
  { field: "ProjectDescription", flex:2, wrapText: true, autoHeight: true, cellRenderer: TextToHtml}
]);

const {i18n} = useTranslation()
const [projects, setProjects] = useState()
let currentLanguage = i18n.language
if(i18n.language === 'ye'){
  currentLanguage = 'ye-YE'
} else if (i18n.language === 'de'){
  currentLanguage = 'de-DE'
}
const [lang, setLang] = useState(currentLanguage)
const tableNameRef = useRef("projects-history-")

useEffect(() => {
  window.addEventListener('storage', () => {
    console.log("Change to local storage!");
    setLang(window.localStorage.getItem("lang"))
  })
}, []);


useEffect(() => {
  let tableName = tableNameRef.current + lang
  console.log("Table name is now:" + tableName)
    // Get item from DynamoDB
  const params = {
    TableName: tableName,
    Key: {
      'userId': 'ProjectId'
    }
  };
  // React advises to declare the async function directly inside useEffect

  async function getProjectsHistory() {
    const scanResults = [];
    let items;
    do{
        items = await docClient.scan(params).promise();
        items.Items.forEach((item) => scanResults.push(item));
        params.ExclusiveStartKey = items.LastEvaluatedKey;
    }while(typeof items.LastEvaluatedKey !== "undefined");
    setProjects(scanResults)
  };
  if(lang){
    getProjectsHistory();
  }
    
  
}, [lang]);

  return (
    <div>
      <div >
        <Helmet>
          <title>
            Esnad e.V | Projects| Projekte
          </title>
        </Helmet>
        <div className="projects-history">
        <Trans i18nKey="" components={{ p: <p/> , pre: <pre/>, h2: <h2/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
        </div>
        <div
            className="ag-theme-quartz" // applying the Data Grid theme
            style={{height: "800px", width: '70%', marginLeft: 'auto', marginRight: 'auto', paddingBottom: 50}} // the Data Grid will fill the size of the parent container
        >
          <AgGridReact 
          rowData={projects?.length >0 ? projects  : null}
          columnDefs={colDefs}
          />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ProjectsHistory;

/*
<div>
        <table class="styled-table">
          <thead>
            <tr>
                  <td>ProjectDate</td>
                  <td>ProjectType</td>
                  <td>ProjectDescription</td>
            </tr>
          </thead>
          <tbody>
            { 
            //projects?console.log('Projects:', projects): console.log('No Projects')
            projects?.length >0 ? (projects.map((project, index) => (  
              <tr key={index}>  
                <td>{project.ProjectDate}</td>  
                <td>{project.ProjectType}</td>  
                <td>{project.ProjectDescription}</td> 
              </tr>  
            ))):(<tr>  
                <td></td>  
                <td></td>  
                <td></td>  
                <td></td> 
              </tr>  )
             }
          </tbody>
        </table>
        </div>*/