import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from 'react-i18next';
import Card from "./Card";
import "./Pages.css";
const About = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="about">
        <Helmet>
          <title>
           Esnad e.V |  About | Über uns 
          </title>
        </Helmet>
        <div className="cards">
          <Card key={0} text={t("Board")} to={"board"} index={0} isFile={false} />
          {/*<Card key={1} text={t("Constitution")} to={"constitution"} index={1}  isFile={false}/>*/}
          <Card key={2} text={t("Membership")} to={"membership"} index={2} isFile={true} />
        </div>
        <Trans i18nKey="About-Text" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />
        <Trans i18nKey="Vision" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
        <Trans i18nKey="Mission" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
        <Trans i18nKey="Purpose-Goal" components={{ p: <p /> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
        <Trans i18nKey="Structure" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>}} />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
